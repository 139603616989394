let Fossils = [
  {
    name: 'acanthostega',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilFirstFourLegs.png',
    group: 'acanthostega',
    number: 1,
  },
  {
    name: 'amber',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilAmber.png',
    group: 'amber',
    number: 2,
  },
  {
    name: 'ammonite',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilAmmonoidea.png',
    group: 'ammonite',
    number: 3,
  },
  {
    name: 'ankylo skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilAnkylosaurusA.png',
    group: 'Ankylosaurus',
    number: 4,
  },
  {
    name: 'anomalocaris',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilAnomalocaris.png',
    group: 'anomalocaris',
    number: 5,
  },
  {
    name: 'archaeopteryx',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilArchaeopteryx.png',
    group: 'archaeopteryx',
    number: 6,
  },
  {
    name: 'archelon skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilArchelonA.png',
    group: 'Archelon',
    number: 7,
  },
  {
    name: 'australopith',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrFossilAustralopithecus.png',
    group: 'australopith',
    number: 8,
  },
  {
    name: 'brachio skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilBrachiosaurusA.png',
    group: 'Brachiosaurus',
    number: 9,
  },
  {
    name: 'coprolite',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilCoprolite.png',
    group: 'coprolite',
    number: 10,
  },
  {
    name: 'deinony torso',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilDeinonychusA.png',
    group: 'Deinonychus',
    number: 11,
  },
  {
    name: 'dimetrodon skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilDimetrodonA.png',
    group: 'Dimetrodon',
    number: 12,
  },
  {
    name: 'dinosaur track',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilIchnology.png',
    group: 'Dinosaur Track',
    number: 13,
  },
  {
    name: 'diplo skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilDiplodocusA.png',
    group: 'Diplodocus',
    number: 14,
  },
  {
    name: 'dunkleosteus',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilDunkleosteus.png',
    group: 'dunkleosteus',
    number: 15,
  },
  {
    name: 'eusthenopteron',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilFirstAmphibian.png',
    group: 'eusthenopteron',
    number: 16,
  },
  {
    name: 'iguanodon skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilIguanodonA.png',
    group: 'Iguanodon',
    number: 17,
  },
  {
    name: 'juramaia',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilFirstMammal.png',
    group: 'juramaia',
    number: 18,
  },
  {
    name: 'mammoth skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilMammothA.png',
    group: 'Mammoth',
    number: 19,
  },
  {
    name: 'megacero skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilBrontotheriumA.png',
    group: 'Megacerops',
    number: 20,
  },
  {
    name: 'myllokunmingia',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilFirstFish.png',
    group: 'myllokunmingia',
    number: 21,
  },
  {
    name: 'ophthalmo skull',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrFossilOphthalmosaurusA.png',
    group: 'Ophthalmosaurus',
    number: 22,
  },
  {
    name: 'pachy skull',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrFossilPachycephalosaurusA.png',
    group: 'Pachycephalosaurus',
    number: 23,
  },
  {
    name: 'parasaur skull',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrFossilParasaurolophusA.png',
    group: 'Parasaurolophus',
    number: 24,
  },
  {
    name: 'plesio skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilElasmosaurusA.png',
    group: 'Plesiosaurus',
    number: 25,
  },
  {
    name: 'quetzal torso',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrFossilQuetzalcoatlusA.png',
    group: 'Quetzalcoatlus',
    number: 26,
  },
  {
    name: 'sabertooth skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilSmilodonA.png',
    group: 'Sabertooth Tiger',
    number: 27,
  },
  {
    name: 'shark-tooth pattern',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilShark.png',
    group: 'shark-tooth pattern',
    number: 28,
  },
  {
    name: 'spino skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilSpinosaurusA.png',
    group: 'Spinosaurus',
    number: 29,
  },
  {
    name: 'stego skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilStegosaurusA.png',
    group: 'Stegosaurus',
    number: 30,
  },
  {
    name: 'T. rex skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilTRexA.png',
    group: 'T. Rex',
    number: 31,
  },
  {
    name: 'tricera skull',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilTriceratopsA.png',
    group: 'Triceratops',
    number: 32,
  },
  {
    name: 'trilobite',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrFossilTrilobite.png',
    group: 'trilobite',
    number: 33,
  },
]
export default Fossils
