let Gyroids = [
  {
    name: 'aluminoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaDrumSet00_Remake_0_0.png',
  },
  {
    name: 'arfoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody02_Remake_0_0.png',
  },
  {
    name: 'babbloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody01_Remake_0_0.png',
  },
  {
    name: 'bendoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaKick02_Remake_0_0.png',
  },
  {
    name: 'bloopoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaSnare00_Remake_0_0.png',
  },
  {
    name: 'boioingoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaCrash00_Remake_0_0.png',
  },
  {
    name: 'boomoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaKick03_Remake_0_0.png',
  },
  {
    name: 'brewstoid',
    image_url: 'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaSnare04.png',
  },
  {
    name: 'bubbloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody12_Remake_0_0.png',
  },
  {
    name: 'bwongoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody04_Remake_0_0.png',
  },
  {
    name: 'clatteroid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaSnare03_Remake_0_0.png',
  },
  {
    name: 'crumploid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaSnare02_Remake_0_0.png',
  },
  {
    name: 'dootoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaDrumSet03_Remake_0_0.png',
  },
  {
    name: 'drummoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaDrumSet02_Remake_0_0.png',
  },
  {
    name: 'flutteroid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaHiHat03_Remake_0_0.png',
  },
  {
    name: 'jingloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaCrash02_Remake_0_0.png',
  },
  {
    name: 'laseroid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody06_Remake_0_0.png',
  },
  {
    name: 'oinkoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody10_Remake_0_0.png',
  },
  {
    name: 'petaloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody13_Remake_0_0.png',
  },
  {
    name: 'rattloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaCrash03_Remake_0_0.png',
  },
  {
    name: 'ringoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody09_Remake_0_0.png',
  },
  {
    name: 'rumbloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaKick01_Remake_0_0.png',
  },
  {
    name: 'scatteroid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaHiHat00_Remake_0_0.png',
  },
  {
    name: 'spikenoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaHiHat01_Remake_0_0.png',
  },
  {
    name: 'sproingoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaSnare01_Remake_0_0.png',
  },
  {
    name: 'squeakoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody07_Remake_0_0.png',
  },
  {
    name: 'squeezoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaHiHat02_Remake_0_0.png',
  },
  {
    name: 'stelloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaCrash01_Remake_0_0.png',
  },
  {
    name: 'thwopoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaKick00_Remake_0_0.png',
  },
  {
    name: 'tockoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody08_Remake_0_0.png',
  },
  {
    name: 'tremoloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody11_Remake_0_0.png',
  },
  {
    name: 'twangoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody05_Remake_0_0.png',
  },
  {
    name: 'wallopoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaDrumSet01_Remake_0_0.png',
  },
  {
    name: 'whirroid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaDrumSet04_Remake_0_0.png',
  },
  {
    name: 'whistloid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody03_Remake_0_0.png',
  },
  {
    name: 'xylophoid',
    image_url:
      'https://acnhcdn.com/latest/FtrIcon/FtrHaniwaMelody00_Remake_0_0.png',
  },
]

export default Gyroids
