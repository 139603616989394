let Achievments = [
  { number: 1, name: '(island name) Miles!', category: 'Island Resident' },
  { number: 2, name: 'Angling for Perfection!', category: 'Fish & Shells' },
  { number: 3, name: 'Island Ichthyologist', category: 'Fish & Shells' },
  { number: 4, name: 'Island Togetherness', category: 'Togetherness' },
  { number: 5, name: "You've Got the Bug", category: 'Bugs' },
  { number: 6, name: "Bugs Don't Bug Me", category: 'Bugs' },
  { number: 7, name: 'Have a Nice DIY!', category: 'DIY' },
  { number: 8, name: 'Deep Dive', category: 'Sea Creatures' },
  { number: 9, name: 'Underwater Understudy', category: 'Sea Creatures' },
  { number: 10, name: 'DIY Tools', category: 'DIY' },
  { number: 11, name: 'DIY Furniture', category: 'DIY' },
  { number: 12, name: 'Furniture Freshener', category: 'DIY' },
  { number: 13, name: 'Rough-hewn', category: 'DIY' },
  { number: 14, name: 'Writing a Cookbook?', category: 'Cooking' },
  { number: 15, name: 'Mmm-Mmm-Miles!', category: 'Cooking' },
  { number: 16, name: 'Trashed Tools', category: 'Tools' },
  { number: 17, name: 'Rock-Splitting Champ', category: 'Tools' },
  { number: 18, name: 'Bona Fide Bone Finder!', category: 'Tools' },
  { number: 19, name: 'Fossil Assessment', category: 'Tools' },
  { number: 20, name: 'Nice to Meet You, Gyroid!', category: 'Tools' },
  { number: 21, name: 'Gyroid Getter', category: 'Tools' },
  { number: 22, name: 'Greedy Weeder', category: 'Plants' },
  { number: 23, name: 'Flower Power', category: 'Plants' },
  { number: 24, name: 'Flower Tender', category: 'Plants' },
  { number: 25, name: "Tomorrow's Trees Today", category: 'Plants' },
  { number: 26, name: 'Pick of the Bunch', category: 'Plants' },
  { number: 27, name: 'Fruit Roots', category: 'Plants' },
  { number: 28, name: 'Shrubbery Hubbubbery', category: 'Shrubs' },
  { number: 29, name: 'Sprout Out Loud', category: 'Crops' },
  { number: 30, name: 'Executive Producer', category: 'Crops' },
  { number: 31, name: 'Go Ahead. Be Shellfish!', category: 'Fish & Shells' },
  { number: 32, name: 'Clam and Collected', category: 'Fish & Shells' },
  { number: 33, name: "Trash Fishin'", category: 'Fish & Shells' },
  { number: 34, name: 'Cast Master', category: 'Fish & Shells' },
  { number: 35, name: 'Dream House', category: 'Island Resident' },
  { number: 36, name: 'Decorated Decorator', category: 'Island Resident' },
  { number: 37, name: 'Hoard Reward', category: 'Island Resident' },
  { number: 38, name: 'Good Things in Store!', category: 'Island Resident' },
  { number: 39, name: 'Remarkable Remodeler', category: 'Island Resident' },
  { number: 40, name: 'Smile Isle', category: 'Togetherness' },
  { number: 41, name: 'Reaction Ruler', category: 'Togetherness' },
  { number: 42, name: 'Island Shutterbug', category: 'NookPhone' },
  { number: 43, name: 'Edit Credit', category: 'NookPhone' },
  { number: 44, name: 'NookPhone Life', category: 'NookPhone' },
  { number: 45, name: "That's One Smart Phone", category: 'NookPhone' },
  { number: 46, name: 'Shop to It', category: 'NookPhone' },
  { number: 47, name: 'Growing Collection', category: 'NookPhone' },
  { number: 48, name: 'Nook Miles for Miles!', category: 'NookPhone' },
  { number: 49, name: 'First-Time Buyer', category: 'Bells' },
  { number: 50, name: 'Seller of Unwanted Stuff', category: 'Bells' },
  { number: 51, name: 'Moving Fees Paid!', category: 'Bells' },
  { number: 52, name: 'Bell Ringer', category: 'Bells' },
  { number: 53, name: 'Miles for Stalkholders', category: 'Bells' },
  { number: 54, name: 'Cornering the Stalk Market', category: 'Bells' },
  { number: 55, name: 'No More Loan Payments!', category: 'Bells' },
  { number: 56, name: 'Bulletin-Board Benefit', category: 'Togetherness' },
  { number: 57, name: 'Popular Pen Pal', category: 'Togetherness' },
  { number: 58, name: 'Flea Flicker', category: 'Bugs' },
  { number: 59, name: 'Cicada Memories', category: 'Bugs' },
  { number: 60, name: 'Netting Better!', category: 'Bugs' },
  { number: 61, name: 'Pit-y Party', category: 'Tools' },
  { number: 62, name: 'Taking the Sting Out', category: 'Misadventures' },
  { number: 63, name: 'Faint of Heart', category: 'Misadventures' },
  { number: 64, name: 'Overcoming Pitfalls', category: 'Misadventures' },
  { number: 65, name: 'Faked Out!', category: 'Misadventures' },
  { number: 66, name: 'Lost Treasure', category: 'Misadventures' },
  { number: 67, name: "It's Raining Treasure!", category: 'Exterior' },
  { number: 68, name: 'Fun with Fences', category: 'Exterior' },
  { number: 69, name: 'Snowmaestro', category: 'Exterior' },
  { number: 70, name: 'Wishes Come True', category: 'Exterior' },
  { number: 71, name: 'Exterior Decorator', category: 'Exterior' },
  { number: 72, name: '(island name) Icons', category: 'Icons' },
  { number: 73, name: 'Island Designer', category: 'Island Designer' },
  { number: 74, name: 'Wispy Island Secrets', category: 'Togetherness' },
  { number: 75, name: "Gulliver's Travails", category: 'Togetherness' },
  { number: 76, name: 'K.K. Mania', category: 'Togetherness' },
  { number: 77, name: 'True Patron of the Arts', category: 'Togetherness' },
  { number: 78, name: 'You Otter Know', category: 'Togetherness' },
  { number: 79, name: 'Set Sail for Adventure', category: 'Togetherness' },
  { number: 80, name: 'Come Home to the Roost', category: 'Togetherness' },
  { number: 81, name: 'Stretch to Refresh!', category: 'Togetherness' },
  { number: 82, name: 'True Friends', category: 'Togetherness' },
  { number: 83, name: 'Birthday Celebration', category: 'Togetherness' },
  { number: 84, name: 'Happy Birthday!', category: 'Island Resident' },
  { number: 85, name: 'Fishing Tourney!', category: 'Fish & Shells' },
  { number: 86, name: 'Bug-Off!', category: 'Bugs' },
  { number: 87, name: 'Countdown Celebration', category: 'Island Resident' },
  { number: 88, name: 'Making a Change', category: 'Custom Design' },
  { number: 89, name: 'First Custom Design!', category: 'Custom Design' },
  { number: 90, name: 'Custom Design Pro!', category: 'Custom Design' },
  { number: 91, name: 'Paydirt!', category: 'Bells' },
  { number: 92, name: 'Shady Shakedown', category: 'Plants' },
  { number: 93, name: 'Golden Milestone', category: 'Golden Tools' },
  { number: 94, name: 'Island and Yourland', category: 'Togetherness' },
  { number: 95, name: 'Host the Most', category: 'Togetherness' },
  { number: 96, name: 'Active Island Resident', category: 'Island Resident' },
]

export default Achievments
